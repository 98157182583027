import './Title.css';

const Title = () => {
  return (
    <div>
      <div className="word">
        <span className="letter" style={{ transform: 'rotate(-15deg)' }}>P</span>
        <span className="letter" style={{ transform: 'rotate(4deg)' }}>A</span>
        <span className="letter" style={{ transform: 'rotate(-7deg)' }}>G</span>
        <span className="letter" style={{ transform: 'rotate(6deg)' }}>E</span>
      </div>
      <div className="word">
        <span className="letter" style={{ transform: 'rotate(0deg)' }}>P</span>
        <span className="letter" style={{ transform: 'rotate(-15deg)' }}>A</span>
        <span className="letter" style={{ transform: 'rotate(-7deg)' }}>L</span>
        <span className="letter" style={{ transform: 'rotate(1deg)' }}>E</span>
        <span className="letter" style={{ transform: 'rotate(-10deg)' }}>T</span>
        <span className="letter" style={{ transform: 'rotate(6deg)' }}>T</span>
        <span className="letter" style={{ transform: 'rotate(13deg)' }}>E</span>
      </div>
    </div>
  );
};

export default Title;