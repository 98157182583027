import './StartButton.css';
import { InstagramLogin } from '@amraneze/react-instagram-login';

interface StartButtonProps {
    onClick: () => void;
    onLoginFailure: () => void;
    onLoginSuccess: (authorizationCode: string) => void;
}

const StartButton = ({ onClick, onLoginSuccess, onLoginFailure }: StartButtonProps) => {
    const clickHandler = () => {
        const childElement = document.querySelector('.start-button-login');
    
        if (childElement) {
          (childElement as HTMLElement).click();
        }

        onClick();
    };

    return (
        <div className="start-button" onClick={clickHandler}>
            <InstagramLogin
                clientId="360615303721477"
                onFailure={onLoginFailure}
                onSuccess={onLoginSuccess}
                redirectUri="https://www.pagepalette.ai/"
                cssClass="start-button-login">
            </InstagramLogin>
            <div className="start-button-text">
                click here
                <br />
                to start
            </div>
        </div>
    );
};

export default StartButton;