import Aperture from './Aperture';
import brush from './images/brush.svg';
import StartButton from './StartButton';
import Title from './Title';
import { useState } from 'react';

const Home = () => {
    const [colors, setColors] = useState<string[]>([]);
    const [igHandle, setIgHandle] = useState<string>("");
    const [personality, setPersonality] = useState<string>("");   
    const [showAperture, setShowAperture] = useState<boolean>(false);

    const onStart = () => {
        setShowAperture(true);
    };

    const onLoginFailure = () => {
        console.error('Login failure');
        setShowAperture(false);
    };

    const onLoginSuccess = async (authorizationCode: string) => {
        const url = 'https://api.pagepalette.ai/analyze';
        const body = {
            'authorization_code': authorizationCode,
        };
        try {
            const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
            });

            if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            console.log(result);

            setIgHandle(result["ig_handle"]);
            setPersonality(result["personality"])
            setColors(result["clusters"]);
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setShowAperture(false);
        }
    }

    let loginElement;
    if (igHandle) {
      loginElement = <div>
        <h1>Welcome, {igHandle}!</h1>;
        <h3>Your personality is {personality}.</h3>
      </div>
    } else {
      loginElement = <StartButton
        onClick={onStart}
        onLoginSuccess={onLoginSuccess}
        onLoginFailure={onLoginFailure} />;
    }
  
    return (<div className="app">
        <Aperture visible={showAperture} />
        <Title />
        <h2 className="subtitle">Discover your online color story</h2>
        {loginElement}
        <div className="color-container" style={{ display: 'flex', gap: '10px' }}>
            {colors.map((color, index) => (
                <div
                key={index}
                style={{
                    width: '100px',
                    height: '100px',
                    backgroundColor: color,
                    margin: '10px',
                }}
                />
            ))}
        </div>
        <div className={`brush-overlay ${ showAperture ? 'hide' : '' }`} >
            <img src={brush} alt="Brush" draggable="false" className="brush" />
        </div>
    </div>);
};

export default Home;
