import './Aperture.css';
// import useScreenSize from './useScreenSize';
import useAnimationFrame from './useAnimationFrame';
import { useState } from 'react';

interface ApertureProps {
    visible: boolean;
}

const Aperture = ({ visible }: ApertureProps) => {
    // const { height, width } = useScreenSize();
    const [jitterX, setJitterX] = useState<number>(0);
    const [jitterY, setJitterY] = useState<number>(0);
    useAnimationFrame((timeMsec: number) => {
        if (visible) {
            setJitterX(visible ? Math.sin(timeMsec / 1000) * 20 : 0);
            setJitterY(visible ? Math.cos(timeMsec / 1000) * 50 : 0);
        }
    }, [visible]);

    let leftAnchor = {
        x: 210 + jitterX,
        y: 445 - jitterY,
    };

    let rightAnchor = {
        x: 900 - jitterX,
        y: 330 + jitterY,
    };

    let slope = (rightAnchor.y - leftAnchor.y) / (rightAnchor.x - leftAnchor.x);
    let leftInterceptY = leftAnchor.y - slope * leftAnchor.x;
    let rightInterceptY = slope * 1110 + leftInterceptY;

    return (
        <div>
            <svg className={`aperture left ${ visible ? 'animate' : ''}`} viewBox="0 0 1110 790" preserveAspectRatio="none">
                <path d={`M0 0 H412 L${leftAnchor.x} ${leftAnchor.y} H0 Z`} fill="#F76440"/>
                <path d={`M0 ${leftAnchor.y} H${leftAnchor.x} L424 790 H0 Z`} fill="#FECA04"/>
            </svg>
            <svg className={`aperture right ${ visible ? 'animate' : ''}`} viewBox="0 0 1110 790" preserveAspectRatio="none">
                <path d={`M1110 0 H726 L${rightAnchor.x} ${rightAnchor.y} H1110 Z`} fill="#ABD9F0"/>
                <path d={`M1110 ${rightAnchor.y} H${rightAnchor.x} L726 790 H1110 Z`} fill="#D8DC53"/>
            </svg>
            <svg className={`aperture top ${ visible ? 'animate' : ''}`} viewBox="0 0 1110 790" preserveAspectRatio="none">
                <path d={`M0 0 H1110 V${rightInterceptY} L0 ${leftInterceptY} V0 Z`} fill="#FD9905"/>
            </svg>
            <svg className={`aperture bottom ${ visible ? 'animate' : ''}`} viewBox="0 0 1110 790" preserveAspectRatio="none">
                <path d={`M1110 ${rightInterceptY - 1} V790 H0 V${leftInterceptY - 1} Z`} fill="#FEA5C9"/>
            </svg>
        </div>
    );
};

export default Aperture;