import './Static.css';
import bottomLeft from './images/privacy-bottom-left.svg';
import bottomRight from './images/privacy-bottom-right.svg';

const Privacy = () => {
    return (<div className="app static-container" style={{gap: 0}}>
        <img src={bottomLeft} alt="background" draggable="false" className="bg-bottom-left" />
        <img src={bottomRight} alt="background" draggable="false" className="bg-bottom-right" />
        <h1 className="static-title">PAGE PALETTE</h1>
        <h2 className="static-subtitle">PRIVACY</h2>
        <div className="static-body">
            <p>Page Palette does not save your login information. It only temporarily accesses your Instagram profile for analysis and all data is deleted immediately after.</p>
        </div>
    </div>);
};

export default Privacy;