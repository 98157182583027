import './Static.css';
import bottomLeft from './images/about-bottom-left.svg';
import topRight from './images/about-top-right.svg';

const About = () => {
    return (<div className="app static-container" style={{gap: 0}}>
        <img src={topRight} alt="background" draggable="false" className="bg-top-right" />
        <img src={bottomLeft} alt="background" draggable="false" className="bg-bottom-left" />
        <h1 className="static-title">PAGE PALETTE</h1>
        <h2 className="static-subtitle">ABOUT</h2>
        <div className="static-body">
            <p>Page Palette analyzes your linked account's page and suggests a unique
            palette of five colors that we think best represent its essence.</p>
            <p>Whether you're aiming to enhance your brand, refresh your profile, or
            simply explore the hues that define your digital space, we hope Page
            Palette provides the personalized color insights you're looking for.</p>
            <br />
            <p>With colorful wishes,</p>
            <p style={{fontWeight: "bold"}}>
            Patrice Pinardo and Bandi Enkhamgalan
            </p>
        </div>
    </div>);
};

export default About;