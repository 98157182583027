import { useRef, useEffect } from 'react';

// adapted from https://css-tricks.com/using-requestanimationframe-with-react-hooks/

const useAnimationFrame = (callback: (timeMsec: number) => void, deps: [any]) => {
    // Use useRef for mutable variables that we want to persist
    // without triggering a re-render on their change
    const requestRef = useRef<number>();

    const animate = (timeMsec: number) => {
        callback(timeMsec)
        requestRef.current = requestAnimationFrame(animate);
    }

    useEffect(() => {
        requestRef.current = requestAnimationFrame(animate);
        return () => cancelAnimationFrame(requestRef.current as number);
    }, deps);
};

export default useAnimationFrame;
