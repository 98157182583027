import './App.css';
import { Routes, Route } from 'react-router-dom';
import About from './About';
import Home from './Home';
import Privacy from './Privacy';
import SelfHidingLink from './SelfHidingLink';

const App = () => {
  return (<>
      <div className="app-container">
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/privacy" element={<Privacy />} />
        </Routes>
      </div>
      <div className="bottom-nav">
        <SelfHidingLink to="/">Home</SelfHidingLink>
        <SelfHidingLink to="/about">About</SelfHidingLink>
        <SelfHidingLink to="/privacy">Privacy</SelfHidingLink>
      </div>
    </>)
};

export default App;