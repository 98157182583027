
import { Link, useLocation } from 'react-router-dom';
import { ReactNode } from 'react';

type SelfHidingLinkProps = {
    to: string;
    children: ReactNode;
};
  
const SelfHidingLink = ({ to, children }: SelfHidingLinkProps) => {
    const location = useLocation();

    if (location.pathname === to) {
        return null;
    }

    return <Link to={to}>{children}</Link>;
};

export default SelfHidingLink;
